import React from "react"
import Index from './index.js'

const IndexPage = () => {
  return (
    <div>
    <Index/>
    </div>
  )
}




export default IndexPage
